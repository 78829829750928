/*
Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

@use './base';

.custom-header {
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.date-time-container > * {
  min-width: 18rem;
}
