/*
Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

.centered {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}


.back_ground_white {
  background-color: White;
}

.back_ground_aqua_marine {
  background-color:  Aquamarine;
}

.back_ground_antique_white {
  background-color: antiquewhite;
}

.back_ground_alice_blue {
  background-color: AliceBlue;
}

.back_ground_light_sky_blue {
  background-color: LightSkyBlue;
}

.back_ground_blue {
  background-color: Blue;
}

.back_ground_orange {
  background-color: Orange;
}

.back_ground_red {
  background-color: Red;
}

.back_ground_misty_rose {
  background-color: MistyRose;
}

.back_ground_light_salmon {
  background-color: LightSalmon;
}

.back_ground_medium_orchid {
  background-color: MediumOrchid;
}

.back_ground_peach_puff {
  background-color: PeachPuff;
}

.back_ground_pale_violet_red {
  background-color: PaleVioletRed;
}

.back_ground_lavender {
  background-color: Lavender;
}

.back_ground_light_cyan {
  background-color: LightCyan;
}

.back_ground_light_pink {
  background-color: LightPink;
}

.back_ground_light_blue {
  background-color: LightBlue;
}

.back_ground_black {
  background-color: Black;
}

.back_ground_honey_dew {
  background-color: HoneyDew;
}

.color_red {
  color: Red;
}

.color_blue {
  color: Blue;
}

.color_royal_blue {
  color: RoyalBlue;
}

.color_light_blue {
  color: LightBlue;
}

.color_deep_sky_blue {
  color: DeepSkyBlue;
}

.color_dodger_blue {
  color: DodgerBlue;
}

.color_dark_blue {
  color: DarkBlue;
}

.color_aqua_marine {
  color: Aquamarine;
}

.color_green {
  color: Green;
}

.color_orange {
  color: Orange;
}

.color_dark_orange {
  color: DarkOrange;
}

.color_magenta {
  color: Magenta;
}

.border_black {
  border-color: black;
  border-style: solid;
  border-width: 1px;
}

.border_blue {
  border-color: Blue;
  border-style: solid;
  border-width: 1px;
}

.border_green {
  border-color: Green;
  border-style: solid;
  border-width: 1px;
}

.border_white {
  border-color: white;
  border-style: solid;
  border-width: 1px;
}

.border_orange {
  border-color: orange;
  border-style: solid;
  border-width: 1px;
}

.spacing_2 {
  padding: 2px;
}

.spacing_5 {
  padding: 5px;
}

.spacing_6 {
  padding: 6px;
}

.spacing_10 {
  padding: 10px;
}

.text_white {
  color: white;
}

.bold_text {
  font-weight: bold;
}

.vertical_align_top {
  vertical-align: text-top;
}

.image_size {
  height: 30%;
  width: 30%;
}

.max_width_50 {
  max-width: 50%;
}

.max_width_75 {
  max-width: 75%;
}

.max_width_100 {
  max-width: 100%;
}

.width_100 {
  width: 100%;
}

.tab {
  margin-left: 1.5em
}

.show-white-space {
  white-space: pre-wrap;
}

.display_block {
  display: block;
}